
import { defineComponent, onMounted, ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import BaseService from "@/services/admin/BaseService";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const turmas = ref<any>([]);
    const loading = ref<boolean>(false);
    const id: any = route.params.id;

    const getTurma = (): void => {
      BaseService.list(`turmas/avaliacoes/${id}`)
        .then((res) => {
          turmas.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };


    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getTurma();
    });

    return {
      turmas
    };
  },
});
